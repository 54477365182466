import React, { useState, useEffect } from "react";
import { useMutation } from "@apollo/client";

import { connectionsGet, groupGet } from "Apollo/Queries";
import { groupPut, connectionPut, evaluationPut } from "Apollo/Mutations";

import { Table, Button, Card, Modal, Tag } from "Components/elements";

import styles from "./StartupList2.module.css";
import ShareSetting from "./ShareSetting";
import { group as group_route, startup_page } from "pages/definitions";
import classnames from 'classnames';


const getSummaries = (startups, hide) => {

  let summaries = {
    subjectiveScores: 0,
    subjectiveScoresCount: 0,
    averageSubjectiveScore: 0,
    evaluations: {}
  } 

  for (let startup of startups) {

    // Get subjective score summaries    
    if (startup.subjective_score) {

      let subjectiveScores = startup.connection?.subjectiveScores || []
      let { score } = subjectiveScores.find(({createdByUser}) => 
        createdByUser.email === startup.sharedBy
      ) || {}

      if (score) {
        summaries.subjectiveScores += score;
        summaries.subjectiveScoresCount += 1;
      }
    }

    summaries.averageSubjectiveScore = parseFloat((summaries.subjectiveScores / summaries.subjectiveScoresCount).toFixed(1))


    // Get evaluation summaries
    if (startup.evaluations) {

      let evaluations = (startup.connection?.evaluations || [])
        .filter(({createdByUser}) => 
          createdByUser.email === startup.sharedBy
      ) || [];

      for (let evaluation of evaluations) {

        let { templateId, sections } = evaluation;

        summaries.evaluations[templateId] = 
          summaries.evaluations[templateId] || {
          totalScore: 0,
          averageScore: 0,
          percentageScore: 0,
          count: 0,
          sections: {},
        }
        summaries.evaluations[templateId].templateName = evaluation.summary.templateName;
        summaries.evaluations[templateId].totalScore += evaluation.summary.totalScore;
        summaries.evaluations[templateId].count += 1;
        summaries.evaluations[templateId].possibleScore = evaluation.summary.possibleScore;

        summaries.evaluations[templateId].averageScore =
          summaries.evaluations[templateId].totalScore / summaries.evaluations[templateId].count

        summaries.evaluations[templateId].percentageScore =
          Math.round((summaries.evaluations[templateId].averageScore / summaries.evaluations[templateId].possibleScore)*100)
      
        for (let section of evaluation.summary.sections) {
          summaries.evaluations[templateId].sections[section.id] =
            summaries.evaluations[templateId].sections[section.id] || {
            totalScore: 0,
            count: 0
          }
          summaries.evaluations[templateId].sections[section.id].sectionName = section.name;
          summaries.evaluations[templateId].sections[section.id].totalScore += (section.score || 0);
          summaries.evaluations[templateId].sections[section.id].possibleScore = section.possibleScore;
          summaries.evaluations[templateId].sections[section.id].count += 1;
        }

      }

    }

  }

  return summaries
}

const getListOfStartups = ({group, sortBy, hide}) => {
  let ss = {};
  for (let startup of group.startups) {
    ss[startup.creativeId] = ss[startup.creativeId] || [];
    ss[startup.creativeId].push(startup);
  }
  let list = [];
  for (let creativeId in ss) {
    if (ss[creativeId]?.[0]?.connection) {
      let item = {
        creative: ss[creativeId][0].connection.creative,
        startups: ss[creativeId],
        summaries: getSummaries(ss[creativeId], hide)
      };
      list.push(item);
    }
  }
  
  if (sortBy === "name") {
    list = list.sort((a, b) => {
      if(a.creative.name < b.creative.name) { return -1; }
      if(a.creative.name > b.creative.name) { return 1; }
      return 0;
    })
  }

  if (sortBy === "subjectiveScores") {
    list = list.sort((a, b) => {
      return b.summaries.averageSubjectiveScore - a.summaries.averageSubjectiveScore
    })
  }

  let [ label, templateId] = sortBy.split("::")
  if (label === 'template') {
    list = list.sort((a, b) => {
      let aVal = a.summaries?.evaluations?.[templateId]?.percentageScore || 0;
      let bVal = b.summaries?.evaluations?.[templateId]?.percentageScore || 0;
      return bVal - aVal
    })
  }
  return list;
}

const getAllUsedTemplates = list => {
  let templates = {}
  for (let item of list) {
    let { evaluations } = item.summaries;
    for (let key of Object.keys(evaluations)) {
      templates[key] = evaluations[key].templateName
    }
  }

  let res = []
  for (let templateId in templates) {
    res.push({
      templateId: templateId,
      templateName: templates[templateId]
    })
  }

  res = res.sort((a, b) => {
    if(a.templateName < b.templateName) { return -1; }
    if(a.templateName > b.templateName) { return 1; }
    return 0;    
  })

  return res
}



function AddStartup({creative, connections, isLoading}) {

  const [mutate, { loading }] = useMutation(connectionPut);

  return (
    <div
      className={styles.action_link}
      onClick={async () => {

        if (loading) return;

        try {
          let res = await mutate({
            variables: {
              creativeId: creative.id,
            },
            update: (proxy, { data: { connectionPut } }) => {
              const data = proxy.readQuery({
                query: connectionsGet,
              });
              proxy.writeQuery({
                query: connectionsGet,
                data: {
                  connectionsGet: [
                    ...connections,
                    connectionPut
                  ]
                },
              });
            },
          });
        } catch (error) {
          console.log(error);
        }

      }}
    >


      {
        loading || isLoading && (
          <i className="fa fa-spinner fa-spin" />
        ) || (
          <i className="fal fa-cloud-download" />
        )
      }

      <span>save startup</span>


    </div>
  )
}

function ShareBack({connection, group, isLoading}) {

  const [showShareSettings, setShowShareSettings] = useState(null);
  const [mutate, {loading} ] = useMutation(groupPut, {
    refetchQueries: [
      {
        query: groupGet,
        variables: { id: group.id },
      },
    ],
  });  

  return (
    <>
      <div
        className={styles.action_link}
        onClick={() => {
          setShowShareSettings(connection);
        }}
        >

        {
          loading || isLoading  && (
            <i className="fa fa-spinner fa-spin" />
          ) || (
            <i className="fal fa-share-alt" />
          )
        }

        <span>share back</span>

      </div>


      {showShareSettings && (
        <Modal
          title="Share startup"
          close={() => {
            setShowShareSettings(null);
          }}
          disableFoot={true}
        >
          <ShareSetting
            group={group}
            connection={showShareSettings}
            mutate={mutate}
            done={() => {
              setShowShareSettings(null);
            }}
          />
          <div
            style={{
              position: "absolute",
              left: "26px",
              bottom: "33px",
            }}
          >
            <Button
              buttonStyle="secondary"
              size="medium"
              onClick={() => setShowShareSettings(null)}
            >
              cancel
            </Button>
          </div>
        </Modal>
      )}

    </>
  )
}

function TemplateLogic({group, connections, connection, creative, user, history}) {

  const [currentLoading, setCurrentLoading] = useState("");
  const [mutate, { loading }] = useMutation(evaluationPut);  

  let { evaluationTemplates } = group;

  let mySharedStartup = group.startups.find(({connectionId, sharedBy}) =>
    connection.id === connectionId && sharedBy === user.email
  )

  let unusedEvaluationTemplates = evaluationTemplates.filter(({id}) => 
    !mySharedStartup.connection.evaluations.some(({templateId}) => templateId === id)
  )

  if (!unusedEvaluationTemplates.length) {
    return <span/>
  }

  return (
    <div className={styles.evaluation_templates_container}>

      <div className={styles.evaluation_templates_label}>
        You are kindly requested to complete the following evaluations:
      </div>

      {
        unusedEvaluationTemplates.map((template) => {

          return (
            <div key={template.id}>
              <Button
                type="right_arrow"
                size="medium"
                // onClick={() => {
                //  console.log(template)
                // }}

                loading={currentLoading === template.id}
                onClick={async () => {

                  if (currentLoading === template.id) return;

                  setCurrentLoading(template.id);

                  let sectionId = template.sections[0]?.id;

                  try {
                    let variables = {
                      connectionId: connection.id,
                      groupId: group.id,
                      input: {
                        templateId: template.id,
                        name: template.name,
                        description: template.description,
                      },
                    };

                    let res = await mutate({ variables });
                    let evaluation = res.data.evaluationPut;
                    let path = `${startup_page}/${connection.id}/evaluation/${evaluation.id}/section/${sectionId}`;
                    history.push(path);
                  } catch (error) {
                    console.log("error", error);
                  }
                  setCurrentLoading("");
                }}                
                >
                {template.name}
              </Button>
            </div>
          )
        })
      }


    </div>
  )
}

function SortBy({sortBy, setSortBy, allUsedTemplates}) {

  const [ showModal, setShowModal ] = useState(false)
  let [ label, templateId ] = sortBy.split("::")
  
  
  let sortByName = sortBy ? sortBy : 'choose';

  if (sortBy === 'name') {
    sortByName = 'Name';
  }

  if (sortBy === 'subjectiveScores') {
    sortByName = 'Subjective scores';
  }  

  if (templateId) {
    let template = allUsedTemplates.find(template => template.templateId === templateId);
    sortByName = template?.templateName;
  }


  return (
    <>
      <Card
        style={{
          paddingBottom: "18px",
          marginBottom: 0,
          marginTop: '8px'
        }}
        >
        <span>Sort by: </span>
        <Tag
          onClick={() => setShowModal(true)}
          style={{cursor: "pointer"}}
          // active
          >
          {sortByName}
        </Tag>
      </Card>

      {showModal && (
        <Modal
          title="Sort"
          close={() => {
            setShowModal(false)
          }}
          disableFoot={true}
          >

          <div className={styles.sort_list}>

            <div
              className={classnames(
                styles.sort_list_item,
                sortBy === 'name' && styles.selected_sort_list_item
              )}
              onClick={() => setSortBy("name")}
              >
              Name
            </div>

            <div
              className={classnames(
                styles.sort_list_item,
                sortBy === 'subjectiveScores' && styles.selected_sort_list_item
              )}
              onClick={() => setSortBy("subjectiveScores")}
              >
              Subjective score
            </div>

            {
              allUsedTemplates.map(({templateId, templateName}) => (
                <div
                  key={templateId}
                  className={classnames(
                    styles.sort_list_item,
                    sortBy === `template::${templateId}` && styles.selected_sort_list_item
                  )}
                  onClick={() => {
                    setSortBy(`template::${templateId}`)
                  }}
                  >
                  {templateName}
                </div>
              ))
            }
          </div>


          <div style={{textAlign: "right", marginTop: "20px"}}>
            <Button
              size="medium"
              buttonStyle="secondary"
              onClick={() => setShowModal(false)}
              >
              Close
            </Button>
          </div>

        </Modal>

      )}

    </>
  )
}

function HideLogic({hide, setHide, allUsedTemplates}) {

  const [ showModal, setShowModal ] = useState(false)


  let hideItems = [];
  for (let key in hide) {
    if (hide[key]) {

      if (key === 'subjectiveScores') {
        hideItems.push({
          key,
          name: "Subjective scores",
        })
      }

      let template = allUsedTemplates.find(({templateId}) => templateId === key);

      if (template) {
        hideItems.push({
          key,
          name: template.templateName,
        }) 
      }
    }
  }


  return (
    <>
      <Card
        style={{
          paddingBottom: "18px",
          marginBottom: 0,
          marginTop: '8px'
        }}
        >

        <div>
          Hide:
          {
            hideItems.map(item => (
              <Tag
                style={{cursor: "pointer"}}
                className={styles.hide_item}
                key={item.key}
                onClick={() => {
                  setHide({
                    ...hide,
                    [item.key]: !hide[item.key]
                  })
                }}
                >
                <span>{item.name}</span>
                <span className={styles.tag_kill}>
                  <i className="fal fa-times" />
                </span>
              </Tag>
            ))
          }

          <Tag
            // active
            style={{cursor: "pointer"}}
            className={styles.hide_item}
            onClick={() => {
              setShowModal(true)
            }}            
            >
            <span>choose</span>
          </Tag>

        </div>

      </Card>


      {
        showModal && (
          <Modal
            title="Hide"
            close={() => {
              setShowModal(false)
            }}
            disableFoot={true}
            >

            <div className={styles.hide_list}>

              <div
                className={classnames(
                  styles.hide_list_item,
                  hide['subjectiveScores'] && styles.selected_hide_list_item
                )}
                onClick={() => {
                setHide({
                  ...hide,
                  ['subjectiveScores']: !hide['subjectiveScores']
                })
                }}>
                
                {
                  !hide['subjectiveScores'] && (
                    <span>
                      <i className="fal fa-eye" />
                      <span> Subjective score</span>
                    </span>
                  ) || (
                    <span>
                      <i className="fal fa-eye-slash" />
                      <span> Subjective score</span>
                    </span>                
                  )
                }
              </div>

              {
                allUsedTemplates.map(({templateId, templateName}) => (
                  <div
                    key={templateId}
                    className={classnames(
                      styles.hide_list_item,
                      hide[templateId] && styles.selected_hide_list_item                      
                    )}
                    onClick={() => {
                      setHide({
                        ...hide,
                        [templateId]: !hide[templateId]
                      })
                    }}
                    >

                    {
                      !hide[templateId] && (
                        <span>
                          <i className="fal fa-eye" />
                          <span> {templateName}</span>
                        </span>
                      ) || (
                        <span>
                          <i className="fal fa-eye-slash" />
                          <span> {templateName}</span>
                        </span>                
                      )
                    }


                  </div>
                ))
              }


            </div>


            <div style={{textAlign: "right", marginTop: "20px"}}>
              <Button
                size="medium"
                buttonStyle="secondary"
                onClick={() => setShowModal(false)}
                >
                Close
              </Button>
            </div>

          </Modal>
        )
      }

    </>
  )
}


function AddAll({group, list, user, connections, settings, isLoadingDownload, setIsLoadingDownload}) {

  const [isLoadingAddAll, setIsLoadingAddAll] = useState(false);

  const [mutate] = useMutation(groupPut, {
    refetchQueries: [
      {
        query: groupGet,
        variables: { id: group.id },
      },
    ],
  });

  const [connectionPutMutate] = useMutation(connectionPut);


  function getAddAllStatus() {
    let saveAndShare = 0;
    let share = 0;

    for (let g of list) {
      let { creative, startups } = g;

      // let my_startups = haveShared({ startups });
      let match = startups.find(({ sharedBy }) => sharedBy === user.email);

      if (!match) share += 1;

      let match2 = connections.find(
        ({ creativeId }) => creativeId === creative.id
      );
      if (!match2) saveAndShare += 1;
    }
    return { share, saveAndShare };
  }

  const { saveAndShare, share } = getAddAllStatus();


  async function addAllAndShareBack() {

    if (isLoadingAddAll) return;

    setIsLoadingAddAll(true);

    for (let g of list) {
      let { creative, startups } = g;

      let match = connections.find(
        ({ creativeId }) => creativeId === creative.id
      );

      setIsLoadingDownload({ [creative.id]: true });

      // SAVE STARTUP
      if (!match) {
        try {
          await connectionPutMutate({
            variables: {
              creativeId: creative.id,
            },
            update: (proxy, { data: { connectionPut } }) => {
              const data = proxy.readQuery({
                query: connectionsGet,
              });
              proxy.writeQuery({
                query: connectionsGet,
                data: {
                  connectionsGet: [...connections, connectionPut],
                },
              });
              match = connectionPut;
            },
          });
        } catch (error) {
          console.log("error", error);
        }
      }

      try {
        let variables = {
          id: group.id,
          input: {
            addStartup: {
              connectionId: match.id,
              creativeId: match.creativeId,
              comments: true,
              evaluations: true,
              subjective_score: true,
              tags: true,
            },
          },
        };

        await mutate({ variables });
      } catch (error) {
        console.log("error", error);
      }
      setIsLoadingDownload({ [creative.id]: false });
    }

    setIsLoadingAddAll(false);
  }


  let showButton = settings.addStartup && (saveAndShare !== 0 || share !== 0)

  if (!showButton) {
    return <span/>  
  }
  


  return (
    <div>
      <Button
        size="large"
        style={{width: "100%"}}
        loading={isLoadingAddAll}
        iconClass="fas fa-cloud-download"
        onClick={async () => {
          await addAllAndShareBack();
        }}
      >
        sync with group
      </Button>
    </div>
  )
}


function Summaries({ answers }) {
  let tagIds = [
    "q03_section_money",
    "q01_section_business",
    "q03_section_business",
    "q04_section_business",
    "q06_section_business",
    "q04_section_info",
  ];

  let website;
  let w = answers.find(({ questionId }) => questionId === "q06_section_info");
  if (w) {
    w.val.substring(0, 3).toLowerCase() === "htt"
      ? (website = w.val)
      : (website = `http://${w.val}`);
  }

  let d = {
    slideDeck: (
      answers.find(
        ({ questionId }) => questionId === "q01_section_materials"
      ) || {}
    ).val,
    oneLiner: (
      answers.find(({ questionId }) => questionId === "q01_section_info") || {}
    ).val,
    solution: (
      answers.find(({ questionId }) => questionId === "q03_section_info") || {}
    ).val,
    tags: answers.filter(
      ({ questionId, inputType }) =>
        inputType !== "COMMENT" && tagIds.some(id => id === questionId)
    ),
    website,
  };


  if (!d.slideDeck && !d.oneLiner && !d.solution && !d.tags.length && !d.website) {
    return <span/>
  }

  return (
    <div style={{
        padding: "20px 0px",
        marginLeft: "-3px",
        marginRight: "-3px"
      }}>
      {d.oneLiner && <div style={{ padding: "4px", paddingBottom: "20px" }}>{d.oneLiner}</div>}

      {!d.oneLiner && d.solution && (
        <div style={{ padding: "10px" }}>{d.solution}</div>
      )}

      {(d.website || d.slideDeck) && (
        <div style={{ paddingBottom: "10px" }}>
          {d.website && (
            <Tag className={styles.link_tag}>
              <a href={d.website} target="_blank" rel="noopener noreferrer">
                Website <i className="fal fa-external-link-square" />
              </a>
            </Tag>
          )}

          {d.slideDeck && (
            <Tag className={styles.link_tag}>
              <a href={d.slideDeck} target="_blank" rel="noopener noreferrer">
                Slide deck <i className="fal fa-external-link-square" />
              </a>
            </Tag>
          )}
        </div>
      )}

      {!!d.tags.length && d.tags.map(({ val }, i) => <Tag key={i}>{val}</Tag>)}
    </div>
  );
}





function StartupList2({
  group,
  connections,
  settings,
  user,
  isAdmin,
  history
}) {

  const [ sortBy, _setSortBy ] = useState("")
  const [ hide, _setHide ] = useState({})
  const [ isLoadingDownload, setIsLoadingDownload ] = useState({});

  let list = getListOfStartups({group, sortBy, hide})
  let allUsedTemplates = getAllUsedTemplates(list)


  useEffect(() => {
    let f;
    try {
      f = JSON.parse(localStorage.getItem(`group::${group.id}`));
      let { sortBy, hide } = f;
      _setSortBy(sortBy)
      _setHide(hide)
    } catch (error) {}
  }, []);


  function setSortBy(d) {
    _setSortBy(d)
    localStorage.setItem(`group::${group.id}`, JSON.stringify({hide, sortBy: d}));

  }

  function setHide(d) {
    _setHide(d)
    localStorage.setItem(`group::${group.id}`, JSON.stringify({hide: d, sortBy}));    
  }  


  return (
    <div className={styles.container}>

      <AddAll
        group={group}
        list={list}
        user={user}
        connections={connections}
        settings={settings}
        isLoadingDownload={isLoadingDownload}
        setIsLoadingDownload={setIsLoadingDownload}
      />    

      {
        settings.showScores && (
          <>
            <SortBy
              sortBy={sortBy}
              setSortBy={setSortBy}
              allUsedTemplates={allUsedTemplates}
            />

            <HideLogic
              hide={hide}
              setHide={setHide}
              allUsedTemplates={allUsedTemplates}
            />
          </>
        )
      }

      

      {
        list.map(({creative, startups, summaries}) => {

          let haveAddedStartup = connections.find(
            ({ creativeId }) => creativeId === creative.id
          )

          let haveSharedStartup = startups.find(
            ({ sharedBy }) => sharedBy === user.email
          )

          let isLoading = isLoadingDownload[creative.id]


          console.log('creative', creative)


          return (
            <div
              className={styles.startup}
              key={creative.id}
              >

              <div>

                <div
                  className={styles.name}
                  style={{
                    textDecoration: haveAddedStartup ? "underline" : "none",
                    cursor: haveAddedStartup ? "pointer" : "default"
                  }}
                  onClick={() => {
                    if (!haveAddedStartup) return
                    let path = `${startup_page}/${haveAddedStartup.id}`
                    history.push(path)
                  }}
                  >
                  {creative.name}
                </div>
              </div>

              <Summaries
                answers={creative.answers}
              />


              {
                !haveAddedStartup && (
                  <AddStartup
                    creative={creative}
                    connections={connections}
                    isLoading={isLoading}
                  />
                )
              }

              {
                haveAddedStartup && !haveSharedStartup && (
                  <ShareBack
                    connection={haveAddedStartup}
                    group={group}
                    isLoading={isLoading}
                  />
                )
              }           


              {/* SUBJECTIVE SCORE */}

              {
                !hide['subjectiveScores'] &&
                settings.showScores &&
                summaries.subjectiveScoresCount !== 0 && (
                  <div className={styles.list_outer_container}>

                    <div className={styles.list_label}>
                      Subjective score:
                    </div>

                    <div className={styles.list_container}>

                      <div className={styles.list_item}>

                        <div className={styles.list_item_label}>
                          Average subjective score
                        </div>

                        <div className={styles.list_item_right}>

                          <div className={styles.list_item_sub_line}>
                            {summaries.subjectiveScoresCount} submissions
                          </div>

                          <div className={styles.list_item_score}>
                            {summaries.averageSubjectiveScore || 0}
                          </div>

                        </div>

                      </div>

                    </div>
                  </div>
                )
              }


              {/* EVALUATIONS */}
              {
                settings.showScores &&                 
                !!Object.keys(summaries.evaluations)
                  .filter(templateId => !hide[templateId]).length && (
                  <div className={styles.list_outer_container}>
                    <div className={styles.list_label}>
                      Evaluations:
                    </div>
                    <div className={styles.list_container}>

                      {
                        Object.keys(summaries.evaluations)
                          .filter(templateId => !hide[templateId])
                          .map(templateId => {

                          let item = summaries.evaluations[templateId];

                          return (
                            <div
                              key={templateId}
                              className={styles.list_item}
                              >

                              <div className={styles.list_item_label}>
                                {item.templateName}
                              </div>

                              <div className={styles.list_item_right}>

                                <div className={styles.list_item_sub_line}>
                                  {item.count} submissions
                                </div>

                                <div className={styles.list_item_score}>
                                  {item.percentageScore}%
                                </div>

                              </div>


                            </div>
                          )
                        })
                      }
                    </div>
                  </div>
                )
              }


              {/* TEMPLATE LOGIC */}
              {
                haveAddedStartup && haveSharedStartup && (
                  <TemplateLogic
                    group={group}
                    connections={connections}
                    creative={creative}
                    connection={haveAddedStartup}
                    user={user}
                    history={history}
                  />                  
                )
              }


            </div>
          )
        })
      }

    </div>
  )

}


export default StartupList2;
