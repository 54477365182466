import React from "react";
import { Content } from "Components/elements";

const Comp = () => {
  return (
    <Content maxWidth={1200}>
      <h1>Report</h1>
    </Content>
  );
};

export default Comp;
